<template>
  <div style="display: flex; overflow-x: hidden;">
    <canvas id="i_backgroundCanvas" :style="'background-image: url(' + d_backgroundImage + '); background-attachment: fixed; background-position: center; background-size: ' + full_page.width + ' ' + full_page.height + '!important; width: ' + full_page.width + '; height: ' + full_page.height "></canvas>
    <div style="position: absolute; padding: 20px; width: 100%;" id="i_wisdomMenuPage">
      <b-row>
        <b-col v-if="user.permissions_result && user.permissions_result.indexOf('general_search-patient') !== -1" sm="12" md="6" lg="4">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '108')">?</b-badge>
          <router-link to="/dashboard/search-patient" style="text-decoration: none;">
            <b-card no-body class="text-white s_stableCard">
              <b-card-body class="s_stableCardBody">
                <div class="h4 m-0 text-center">
                  <span style="color:black; font-size:30px"><img src="@/icon/29461.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em; padding: 1px;" /> <b> Hasta Bul</b></span>
                </div>
                <div class="text-center">Kayıtlı hastanızı bulmak için tıklayınız</div>
              </b-card-body>
            </b-card>
          </router-link>
        </b-col>
        <b-col v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" sm="12" md="6" lg="4">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '109')">?</b-badge>
          <router-link to="/patient/new" style="text-decoration: none;">
            <b-card no-body class="text-white s_stableCard">
              <b-card-body class="s_stableCardBody2">
                <div class="h4 m-0 text-center">
                  <span style="color:black; font-size:30px;"><img src="@/icon/2070999.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em; padding: 1px;" /> <b> Yeni Hasta</b></span>
                </div>
                <div class="text-center">Yeni hasta eklemek için tıklayınız</div>
              </b-card-body>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '110')">?</b-badge>
          <router-link to="/help" style="text-decoration: none;">
            <b-card no-body class="text-white s_stableCard">
              <b-card-body class="s_stableCardBody3">
                <div class="h4 m-0 text-center">
                  <span style="color:black; font-size:30px"><img src="@/icon/2724564.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em; padding: 1px;" /> <b> {{ $t('wdm16.6310') }}</b></span>
                </div>
                <div class="text-center"> {{ $t('wdm16.6353') }}</div>
              </b-card-body>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" lg="3" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_my-hospital') !== -1">
          <router-link to="/settings/my-hospital" style="text-decoration: none;">
            <b-card no-body class="text-white s_unstableCard">
              <div class="h4 m-0">
                <b-button class="s_buttonInCard" block variant="primary"><img src="@/icon/2964492.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3.5em; padding: 2px;" /> &nbsp;&nbsp;Hastanemi Yönet</b-button>
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="6" lg="3" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_all-hospitals') !== -1">
          <router-link to="/settings/all-hospitals" style="text-decoration: none;">
            <b-card no-body class="text-white s_unstableCard">
              <div class="h4 m-0">
                <b-button class="s_buttonInCard" block variant="primary"><img src="@/icon/2964492.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3.5em; padding: 2px;" /> &nbsp;&nbsp;Tüm Hastaneleri Yönet</b-button>
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="6" lg="3" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1">
          <router-link to="/settings/hospital/users" style="text-decoration: none;">
            <b-card no-body class="text-white s_unstableCard">
              <div class="h4 m-0">
                <b-button class="s_buttonInCard" block variant="primary"><img src="@/icon/2221920.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3.5em; padding: 2px;" /> &nbsp;&nbsp;Kullanıcıları Yönet</b-button>
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="6" lg="3" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_add-user') !== -1">
          <router-link to="/settings/hospital/users/add" style="text-decoration: none;">
            <b-card no-body class="text-white s_unstableCard">
              <div class="h4 m-0">
                <b-button class="s_buttonInCard" block variant="primary"><img src="@/icon/1658561.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3.5em; padding: 2px;" /> &nbsp;&nbsp;Yeni Kullanıcı Ekle</b-button>
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="6" lg="3" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_add-user') !== -1">
          <router-link to="/settings/hospital/users/bulk_add" style="text-decoration: none;">
            <b-card no-body class="text-white s_unstableCard">
              <div class="h4 m-0">
                <b-button class="s_buttonInCard" block variant="primary"><img src="@/icon/1658561.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3.5em; padding: 2px;" /> &nbsp;&nbsp;Toplu Kullanıcı Ekleme Ekranı</b-button>
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'WHSModulesHospitalSettings',
  data () {
    return {
      store: store,
      user: {},
      d_backgroundImage: require('../../../static/img/PageBackground.jpg'),
      full_page: {'height': '', 'width': ''}
    };
  },
  components: {},
  computed: mapGetters({
    help: 'help'
  }),
  created: function() {
  },
  mounted() {
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    this.handleResize();

    var canvas = document.getElementById('i_backgroundCanvas');
    var can_w = parseInt(this.full_page.width);
    var can_h = parseInt(this.full_page.height);
    var ctx = canvas.getContext('2d');

    // console.log(typeof can_w);

/*    var ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        r: 0,
        alpha: 0,
        phase: 0
      },
*/      var ball_color = {
        r: 200,
        g: 0,
        b: 0
      },
      R = 2,
      balls = [],
      alpha_f = 0.03,

      // Line
      link_line_width = 1.0,
      dis_limit = 350,
      add_mouse_point = true,
      mouse_in = false,
      mouse_ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        r: 0,
        type: 'mouse'
      };

    // Random speed
    function getRandomSpeed(pos) {
      var min = -1,
        max = 1;
      switch (pos) {
        case 'top':
          return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
          break;
        case 'right':
          return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
          break;
        case 'bottom':
          return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
          break;
        case 'left':
          return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
          break;
        default:
          return;
          break;
      }
    }

    function randomArrayItem(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    function randomNumFrom(min, max) {
      return Math.random() * (max - min) + min;
    }
    // console.log(randomNumFrom(0, 10));
    // Random Ball
    function getRandomBall() {
      var pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
      switch (pos) {
        case 'top':
          return {
            x: randomSidePos(can_w),
            y: -R,
            vx: getRandomSpeed('top')[0],
            vy: getRandomSpeed('top')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
          break;
        case 'right':
          return {
            x: can_w + R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('right')[0],
            vy: getRandomSpeed('right')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
          break;
        case 'bottom':
          return {
            x: randomSidePos(can_w),
            y: can_h + R,
            vx: getRandomSpeed('bottom')[0],
            vy: getRandomSpeed('bottom')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
          break;
        case 'left':
          return {
            x: -R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('left')[0],
            vy: getRandomSpeed('left')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
          break;
      }
    }

    function randomSidePos(length) {
      return Math.ceil(Math.random() * length);
    }

    // Draw Ball
    function renderBalls() {
      Array.prototype.forEach.call(balls, function(b) {
        if (!b.hasOwnProperty('type')) {
          ctx.fillStyle = 'rgba(' + ball_color.r + ',' + ball_color.g + ',' + ball_color.b + ',' + b.alpha + ')';
          ctx.beginPath();
          ctx.arc(b.x, b.y, R, 0, Math.PI * 1, true);
          ctx.closePath();
          ctx.fill();
        }
      });
    }

    // Update balls
    function updateBalls() {
      var new_balls = [];
      Array.prototype.forEach.call(balls, function(b) {
        b.x += b.vx;
        b.y += b.vy;

        if (b.x > -(90) && b.x < (can_w + 50) && b.y > -(50) && b.y < (can_h + 50)) {
          new_balls.push(b);
        }

        // alpha change
        b.phase += alpha_f;
        b.alpha = Math.abs(Math.cos(b.phase));
      });

      balls = new_balls.slice(0);
    }

    // loop alpha
    function loopAlphaInf() {

    }

    // Draw lines
    function renderLines() {
      var fraction, alpha;
      for (var i = 0; i < balls.length; i++) {
        for (var j = i + 1; j < balls.length; j++) {

          fraction = getDisOf(balls[i], balls[j]) / dis_limit;

          if (fraction < 1) {
            alpha = (1 - fraction).toString();

            ctx.strokeStyle = 'rgba(58,30,30,' + alpha + ')';
            ctx.lineWidth = link_line_width;

            ctx.beginPath();
            ctx.moveTo(balls[i].x, balls[i].y);
            ctx.lineTo(balls[j].x, balls[j].y);
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
    }

    // calculate distance between two points
    function getDisOf(b1, b2) {
      var delta_x = Math.abs(b1.x - b2.x),
        delta_y = Math.abs(b1.y - b2.y);

      return Math.sqrt(delta_x * delta_x + delta_y * delta_y);
    }

    // add balls if there a little balls
    function addBallIfy() {
      if (balls.length < 10) {
        balls.push(getRandomBall());
      }
    }

    // Render
    function render() {
      ctx.clearRect(0, 0, can_w, can_h);
      renderBalls();
      renderLines();
      updateBalls();
      addBallIfy();
      window.requestAnimationFrame(render);
    }

    // Init Balls
    function initBalls(num) {
      for (var i = 1; i <= num; i++) {
        balls.push({
          x: randomSidePos(can_w),
          y: randomSidePos(can_h),
          vx: getRandomSpeed('top')[0],
          vy: getRandomSpeed('top')[1],
          r: R,
          alpha: 1,
          phase: randomNumFrom(0, 10)
        });
      }
    }
    // Init Canvas

    function initCanvas() {
      if (document.getElementById('i_wisdomMenuPage')) {
        var h = window.innerHeight > document.getElementById('i_wisdomMenuPage').offsetHeight ? window.innerHeight - 85 : document.getElementById('i_wisdomMenuPage').offsetHeight;
        canvas.setAttribute('width', window.innerWidth);
        canvas.setAttribute('height', h);
        can_w = parseInt(canvas.getAttribute('width'));
        can_h = parseInt(canvas.getAttribute('height'));
      }
    }
    window.addEventListener('resize', this.handleResize);

    function goMovie() {
      initCanvas();
      initBalls(50);
      window.requestAnimationFrame(render);
    }
    goMovie();

    window.addEventListener('load', () => {
      initCanvas();
    });
    canvas.addEventListener('mouseenter', function() {
      mouse_in = true;
      balls.push(mouse_ball);
    });
    canvas.addEventListener('mouseleave', function() {
      mouse_in = false;
      var new_balls = [];
      Array.prototype.forEach.call(balls, function(b) {
        if (!b.hasOwnProperty('type')) {
          new_balls.push(b);
        }
      });
      balls = new_balls.slice(0);
    });
    canvas.addEventListener('mousemove', function(e) {
      var e = e || window.event;
      mouse_ball.x = e.pageX;
      mouse_ball.y = e.pageY;
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize: function (e) {
      let width = Math.max( document.getElementById("i_wisdomMenuPage").scrollWidth, document.documentElement.clientWidth );
      let height = Math.max( document.getElementById("i_wisdomMenuPage").scrollHeight, document.documentElement.clientHeight );
      this.full_page.width = width.toString() + 'px';
      this.full_page.height = height.toString() + 'px';
      this.$forceUpdate();
    }
  },
  watch: {}
};

</script>

<style type="text/css">
canvas {
  background-color: #000;
}

.s_unstableCard {
  background-color: rgba(32, 168, 216, 0.5);
  height: 90px;
  box-shadow: inset 0 0 10px #000000;
  padding: 10px;
}

.s_unstableCard:hover {
  background-color: rgba(32, 168, 216, 0.7);
  height: 90px;
  box-shadow: inset 0 0 6px #fff;
  padding: 10px;
}

.s_stableCard {
  border: solid 1.5px;
  border-color: #ababab;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 9px 0px rgba(255, 255, 255, 0.5);
}

.s_stableCardBody {
  box-shadow: inset 0 0 7px #000000;
  background-color: rgba(32, 201, 151, 0.7);
}

.s_stableCardBody:hover {
  box-shadow: inset 0 0 7px #fff;
  background-color: rgba(32, 201, 151, 0.9);
}

.s_stableCardBody2 {
  box-shadow: inset 0 0 7px #000000;
  background-color: rgba(255, 193, 7, 0.7);
}

.s_stableCardBody2:hover {
  box-shadow: inset 0 0 7px #fff;
  background-color: rgba(255, 193, 7, 0.9);
}

.s_stableCardBody3 {
  box-shadow: inset 0 0 7px #000000;
  background-color: rgba(248, 108, 107, 0.7);
}

.s_stableCardBody3:hover {
  box-shadow: inset 0 0 7px #fff;
  background-color: rgba(248, 108, 107, 0.9);
}

.s_buttonInCard {
  background-color: #0b030373;
  border-color: #906969;
  box-shadow: -3px -3px 5px 3px #000000;
  font-size: 18px;
  height: 70px;
}

</style>

